import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Grid,Button, CardActionArea, CardActions, Container, Divider, CardHeader } from '@mui/material';
import CheckoutForm from "./checkoutForm";
import "./pay.css";
import { CreatePayment } from "../helper";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51JI4DSL1a4xqj8lVPH7U2qdf892yHCFnv4Mnh2uQbp0kF2oJa3ulcByP42VdwgKFFBtm1ZH90OZemJfTScmwwI4M00EgWTxmLW");


export default function Pay(props) {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if(props.inputedMap)
    if (clientSecret==""){
      const email=props.email;
    CreatePayment(email, props.inputedMap)
      .then((data) => {setClientSecret(data.clientSecret);});
    }
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
<>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
</>
  );
}