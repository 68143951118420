import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Document, Page ,pdfjs} from "react-pdf";
import ShortTextIcon from '@mui/icons-material/ShortText';
import { useState } from "react";
import { useEffect } from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Draggable from 'react-draggable';
import _cloneDeep from 'lodash/cloneDeep';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { TextField,MenuList, MenuItem, Popper, Grow, Paper, ClickAwayListener, Button, Avatar, Input, Box, Grid, CssBaseline, createTheme, ThemeProvider, ListItem, ListItemAvatar, ListItemText, IconButton, List, ListItemSecondaryAction } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import WcIcon from '@mui/icons-material/Wc';
import { useCallback } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "@fontsource/roboto-mono";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DrawIcon from '@mui/icons-material/Draw';
import { send ,getDefaultLabel,getCurrentLabel} from "./helper";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getformlist } from './helper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import { getfromInputMap } from './helper';
import { ReactSketchCanvas } from "react-sketch-canvas";
import { getPreviewImage } from './helper';


  

  
  


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();



export default function PreviewForm(props) {
  const [previewImage,setPreviewImage]=useState({});
  const [init,setinit]=useState(0);
  async function getPreviewImages(filenames,data){
    console.log(filenames);
    let previewImage={};
    for(let i=0;i<filenames.length;i++){
      let d=await getPreviewImage(filenames[i],data);
      console.log(d);
      previewImage[filenames[i]]=d['body']??[]
    }
    setPreviewImage(previewImage);
  }
  useEffect(()=>{
    getPreviewImages(props.inputedMap["file_name"],props.inputedMap['data']);
  },[init])

  function generatePreview(){
    if(Object.keys(previewImage).length==0){
      return(<></>)
    }
    let outter=[];
    for(let i=0;i<Object.keys(previewImage).length;i++){
      let inner=[];
      console.log(previewImage[Object.keys(previewImage)[i]]);

      for(let k=0;k<previewImage[Object.keys(previewImage)[i]].length;k++){
        inner.push(
          <Grid item xs={12} sm={6} >
            <img src={"data:image/png;base64,"+previewImage[Object.keys(previewImage)[i]][k]} width="100%"/>
          </Grid>
        )
      }
      outter.push(
        <>
        <Typography variant="h6">{props.inputedMap["file_name"][i]}</Typography>
        <Grid container spacing={3} mb="20px">
        {inner}
        </Grid>
        </>)
    }
    return(
      <Box mb={"20px"}>
        <Typography variant="h6">Preview</Typography>
        
        {outter}
        
        </Box>
    )
  }

  return (
    <React.Fragment>
      <Box>
      {generatePreview()}
      </Box>
      <Grid container spacing={3} mt={"20px"}>
        
      </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                
                  <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                
                    <Button

                        variant="contained"
                        onClick={props.handleNext}
                        sx={{ mt: 3, ml: 1 }}
                        >
                        Next
                        </Button>
              </Box>
            
    </React.Fragment>
  );
}