import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Document, Page ,pdfjs} from "react-pdf";
import ShortTextIcon from '@mui/icons-material/ShortText';
import { useState } from "react";
import { useEffect } from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Draggable from 'react-draggable';
import _cloneDeep from 'lodash/cloneDeep';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { OutlinedInput,Stack,Chip,TextField,MenuList, MenuItem, Popper, Grow, Paper, ClickAwayListener, Button, Avatar, Input, Box, Grid, CssBaseline, createTheme, ThemeProvider, ListItem, ListItemAvatar, ListItemText, IconButton, List, ListItemSecondaryAction } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import WcIcon from '@mui/icons-material/Wc';
import { useCallback } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "@fontsource/roboto-mono";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DrawIcon from '@mui/icons-material/Draw';
import { send ,getDefaultLabel,getCurrentLabel} from "./helper";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getformlist } from './helper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import { getfromInputMap } from './helper';
import { ReactSketchCanvas } from "react-sketch-canvas";
import CheckIcon from "@mui/icons-material/Check";


  

  
  


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();



export default function Form(props) {
  const [formlist,setFormlist]=useState([]);
  const [seletedform,setSeletedform]=useState(props.inputedMap['file_name']??[]);
  const [updatekey,setupdatekey]=useState(0);
  const [formInputMap,setFormInputMap]=useState({});
  const [InputedMap,setInputedMap]=useState(props.inputedMap['file_name']??{});
  const [SignCanvasRef,setSignCanvasRef]=useState({});
  useEffect(()=>{
    getformlist().then((data)=>{
      setFormlist(data['body']);
    })
  },[])
  async function helper_load(){
    let mapset={};
    let temp=_cloneDeep(InputedMap);
    let temp2=_cloneDeep(SignCanvasRef);
    let sortedform=seletedform.toSorted();
    for(let i=0;i<sortedform.length;i++){
      let data=await getfromInputMap(sortedform[i]);
      let keys=Object.keys(data['body']);
      //if(seletedform==props.inputedMap['file_name']){
      //  setInputedMap(props.inputedMap['data']);
      //}else{
      for (let i=0;i<keys.length;i++){
        let key=keys[i];
        if(!temp[key]) {
          console.log(key);
          temp[key]={};
        }
        if (!mapset[key]){
          mapset[key]=new Set();
        }
        
        for (let j=0;j<data['body'][key].length;j++){
          let item=data['body'][key][j];
          mapset[key].add(item);
          if (!temp[key][item]){

            ['tickbox','cross'].includes(key)?temp[key][item]=false:temp[key][item]="";
          
            if (key=='Signature'){
            temp2[item]=React.createRef();
          }
        }
      }
      }
      //}
    }
    for (let i=0;i<Object.keys(mapset).length;i++){
      mapset[Object.keys(mapset)[i]]=Array.from(mapset[Object.keys(mapset)[i]]);
    }

    setSignCanvasRef(temp2)
    setInputedMap(temp);
    setFormInputMap(mapset);
  }
  useEffect(()=>{
    if (seletedform==[]){
      return;
    }
    helper_load();  
  },[seletedform])
  useEffect(()=>{
    console.log(InputedMap);
  },[InputedMap])
  function generateInputform(){
    
    let keys=formInputMap?Object.keys(formInputMap):[];
    let ret=[];
    for (let i=0;i<keys.length;i++){
      let key=keys[i];
      let inner=[];
      
      for (let j=0;j<formInputMap[key].length;j++){
        let item=formInputMap[key][j];
        if (['tickbox','cross'].includes(key)){
          inner.push(<Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="secondary" name={item} 
            
              checked={InputedMap[key][item]}
              onChange={(e)=>{ let temp=_cloneDeep(InputedMap);
              temp[key][item]=e.target.checked;
              setInputedMap(temp);}}
              />}
              label={item}
            />
          </Grid>)
        }
        else if (key=='Signature'){
          inner.push(<Grid item xs={12}>
            <Typography variant="caption" gutterBottom>{item}</Typography>
            <ReactSketchCanvas
                ref={SignCanvasRef[item]}
                style={{
                  border: "0.0625rem solid #9c9c9c",
                  borderRadius: "0.25rem",
                }}
                width="100%"
                height="100%"
                strokeWidth={4}
                strokeColor="black"
              />
          </Grid>)
        }
        else if (key=='Date'){
          //date input
          inner.push(
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" gutterBottom>{item}</Typography>
          <TextField
            required
            id={item}
            name={item}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={InputedMap[key][item]}
            onChange={(e)=>{ let temp=_cloneDeep(InputedMap);
              temp[key][item]=e.target.value;
              setInputedMap(temp);}}
            type='date'
          />
        </Grid>
          )
        }
        else{
          inner.push(<Grid item xs={12} sm={6}>
          <TextField
            required
            id={item}
            name={item}
            label={item}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={InputedMap[key][item]}
            onChange={(e)=>{
              let temp=_cloneDeep(InputedMap);
              temp[key][item]=e.target.value;
              setInputedMap(temp);
            }}
          />
        </Grid>)}
        
      
    }
      ret.push(<Box mb={"20px"}>
        <Typography variant="h6">{key}</Typography>
        <Grid container spacing={3}>
        {inner}
        </Grid>
        </Box>)
    
    }
    return ret;
  }

  return (
    <React.Fragment>
      <Box width={"100%"} >
        <FormControl fullWidth>
        <InputLabel >Select Form</InputLabel>
      <Select
        multiple
        value={seletedform}
        onChange={(e) => setSeletedform(e.target.value)}
        input={<OutlinedInput label="Multiple Select" />}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() =>
                  setSeletedform(
                    seletedform.filter((item) => item !== value)
                  )
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        )}
      >
        {formlist.map((item) => (
          <MenuItem
            key={item}
            value={item}
            sx={{ justifyContent: "space-between" }}
          >
            {item}
            {seletedform.includes(item) ? <CheckIcon color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
      
      
      </Box>
      {generateInputform()}
      <Grid container spacing={3} mt={"20px"}>
        
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button

                  variant="contained"
                  onClick={async ()=>{
                    let temp=_cloneDeep(InputedMap);
                    console.log(Object.keys(SignCanvasRef));
                    for(let i=0;i<Object.keys(SignCanvasRef).length;i++){
                      let key=Object.keys(SignCanvasRef)[i];
                      let resp=await SignCanvasRef[key].current.exportImage("png");
                      temp['Signature'][key]=resp;
                    }
                    props.setInputedMap({
                      "file_name":seletedform,
                      "data":temp});
                    props.handleNext()}}
                  sx={{ mt: 3, ml: 1 }}
                >
                 Next
                </Button>
                </Box>
    </React.Fragment>
  );
}