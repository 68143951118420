import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Document, Page ,pdfjs} from "react-pdf";
import ShortTextIcon from '@mui/icons-material/ShortText';
import { useState } from "react";
import { useEffect } from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Draggable from 'react-draggable';
import _cloneDeep from 'lodash/cloneDeep';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { TextField,MenuList, MenuItem, Popper, Grow, Paper, ClickAwayListener, Button, Avatar, Input, Box, Grid, CssBaseline, createTheme, ThemeProvider, ListItem, ListItemAvatar, ListItemText, IconButton, List, ListItemSecondaryAction } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import WcIcon from '@mui/icons-material/Wc';
import { useCallback } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "@fontsource/roboto-mono";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DrawIcon from '@mui/icons-material/Draw';
import { send ,getDefaultLabel,getCurrentLabel} from "./helper";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getformlist } from './helper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import { getfromInputMap } from './helper';
import { ReactSketchCanvas } from "react-sketch-canvas";
import { getPreviewImage } from './helper';
import Pay from "./payments/pay";


  

  
  


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();




export default function Payment(props) {

  const [email,setEmail]=React.useState("");
  const [emailConfirm,setEmailConfirm]=React.useState(false);
  const [updatekey,setUpdatekey]=React.useState(0);
  useEffect(()=>{
    setUpdatekey(updatekey+1)
  },[emailConfirm])
  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent={"space-between"} >
      <TextField fullWidth  label="Receipt Email" variant="outlined" placeholder='abc@example.com' value={email} onChange={(e)=>{
        setEmailConfirm(false);
        setEmail(e.target.value)
        }}/>
      <Button style={{marginLeft:"16px"}} variant="contained" onClick={()=>{
        setEmailConfirm(true);
      }}>confirm</Button>
      </Box>
      <Box key={updatekey}>
      {emailConfirm?
      <Box mt="20px">
      <Pay inputedMap={props.inputedMap} email={email}/>
      </Box>:<></>}
      </Box>
      <Grid container spacing={3} mt={"20px"}>
        
      </Grid>            
    </React.Fragment>
  );
}