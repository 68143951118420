const baseurl="https://4gee6g8ffg.execute-api.ap-east-1.amazonaws.com/Fill_form"
export async function getfromInputMap(file_name) {

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "file_name": file_name
        })
    };
    return (await fetch(baseurl+'/fill_form', requestOptions)).json()
}

export async function getformlist(){
    const resp=await fetch(baseurl+"/fill_form");
    return resp.json();
}
export async function getPreviewImage(file_name,data) {

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
          "file_name": file_name,
          "data":data
      }),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
  };
  return (await fetch(baseurl+'/fill_form/preview', requestOptions)).json()
}
export async function CreatePayment(email, inputedMap){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "email": email,
            "inputedMap":inputedMap
        }),
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          }
    };
    return (await fetch(baseurl+'/payment', requestOptions)).json()
  } 
export async function CheckPayment(paymentintent){
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "paymentintent": paymentintent,
        }),
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          }
    };
    return (await fetch(baseurl+'/payment/check', requestOptions)).json()
  }
