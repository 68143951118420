import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Form from './Form';
import PreviewForm from './PreviewForm';
import Payment from './Payment';
import Success from './payments/Success';
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mi-expert.com/">
        MiExpert Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Filling Form', 'Preview','Pay', 'Get your PDF'];

function getStepContent(step,handleNext,setInputedMap,inputedMap,handleBack) {
  switch (step) {
    case 0:
      return <Form inputedMap={inputedMap} handleNext={handleNext} setInputedMap={setInputedMap}/>;
    case 1:
      return <PreviewForm inputedMap={inputedMap} handleNext={handleNext} handleBack={handleBack}/>;
    case 2:
      return <Payment inputedMap={inputedMap} handleNext={handleNext} handleBack={handleBack}/>;
    case 3:
        return  <Success />
    default:
        throw new Error('Unknown step');
  }
}
//app/order/success?payment_intent=pi_3NaxvNL1a4xqj8lV0W53kjJP&payment_intent_client_secret=pi_3NaxvNL1a4xqj8lV0W53kjJP_secret_76zgcxpuiMl0v3F1L9TOYF2yf&redirect_status=succeeded
export default function Main() {
 
  const [activeStep, setActiveStep] = React.useState(window.location.pathname=="/app/order/success"?3:0);
  
  const [inputedMap,setInputedMap]=React.useState({});
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  React.useEffect(()=>{
    console.log(inputedMap);
  },[inputedMap])
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
          MiExpert Limited
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="100%" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Auto Fill Form
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order
                confirmation, and will send you an update when your order has
                shipped.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep,handleNext,setInputedMap,inputedMap,handleBack)}
              
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}