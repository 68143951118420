import React, { useState, useEffect } from 'react';
import { Grid,Button, CardActionArea, CardActions, Container, Divider, CardHeader,Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import {CheckPayment} from '../helper'

const Success = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const paymentintent = queryParameters.get("payment_intent")
  const [filledpdf, setfilledpdf] = useState([]);
  const [intent, setintent] = useState({});
  useEffect(() => {
    CheckPayment(paymentintent).then((data) => {
      setintent(data['intent']);
      setfilledpdf(data['file']);
    });
  }, [paymentintent]);

  if (intent.receipt_email){
    if( intent.status=="succeeded"){
            return (
              <>   


              <Box width="100%" m={2}>
              <Box display="flex" mt={4}>
                <Typography variant="h6" style={{color:"#1d5963"}}>{Success}</Typography>
              </Box>
              <Divider variant="fullWidth" />
              <Box my={2}>
              <Typography align='left' variant='body2'>{'Please print out this page and keep it for your records'}</Typography>
              <Typography align='left' variant='body1'>{'Account'}: {intent.receipt_email}</Typography>
              <Typography align='left' variant='body1'>{'Payment amount'}: {intent.currency} {intent.amount/100}</Typography>
              <Typography align='left' variant='body1'>{'Receipt '}: <a href={intent.charges.data[0].receipt_url}>Click Here</a></Typography>
              <Typography align='left' variant='body1'>{'We received your purchase request'}<br/> {'Get your filled pdf here'}!</Typography>
              {filledpdf.map((item)=>{
                return <Box>
                  <a href={item['pdf']} download={item['file_name']}>{item['file_name']}</a>
                  </Box>
              })}
              <Typography align='left' variant='body1'>{'It will be expired on 7 days, and we will delete your personal informations and the pdf on our server'}</Typography>
              <Typography align='left' variant='body2'>{'Your transaction id is'} {intent.id}</Typography>
              </Box>
              </Box>
              
            

            </>
            );
    }
    else if( intent.status=="canceled") {
      return (
          <Container>
          <Grid item xs={12} >
          <Typography align='center'><WarningIcon style={{ fontSize: 100,color:"#E52313",alignItems: 'center'}} /></Typography> 
          <Typography  align='center' variant='h2'>{'Transaction failed'}</Typography> 
          <Typography align='center' variant='body2'>{'The transaction is failed, please pay again or Contact our customer service for help'}</Typography>
          </Grid>
          </Container>
  );
    }
  }
  return(<></>);
};

export default Success;
